import React from 'react'

export default function Nav() {
  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-between">
      <div className="navbar-brand brand-logo" href="index-2.html" style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"row",width:"100%",color:"white"}}>
       
        ADMIN
      </div>
      <a className="navbar-brand brand-logo-mini" href="index-2.html">
     
      </a>
  
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
      <div className="d-none d-lg-flex">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="mdi mdi-menu" />
        </button>
        <ul className="navbar-nav mr-lg-2">
       
        </ul>
      </div>
      <div className="navbar-social-link d-none d-xl-flex align-items-center ">
       
      
     
      </div>
      <ul className="navbar-nav navbar-nav-right">
   
      </ul>
      <button
        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span className="mdi mdi-menu" />
      </button>
    </div>
  </nav>
  
  )
}
