import React from 'react'
import SpinnerImg from  './spinner.gif';

export default function Spinner() {
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"60vh"}}>
        <img src={SpinnerImg} alt="spinner" />
    </div>
  )
}
