import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Tree() {
    const [data, setData] = useState([]);
    const [userId, setUserId] = useState("");
    const [auth, setAuth] = useState("");
    const [check, setCheck] = useState(false);
    const [directMembers, setDirectMembers] = useState([]);
    const [loading, setLoading] = useState(false);


    const fetchDirectCounts = async () => {
        try {
            const newData = await Promise.all(data.map(async (item) => {
                const response = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${item.userId}&level=1`);
                const direct = response.data.length;
                return { ...item, direct };
            }));
            setData(newData);
        } catch (error) {
            console.error("Error fetching direct counts:", error);
        }
    };


    const fetchData = async (url) => {
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Rethrow the error to be caught by the caller
        }
    };


    const fetchDirectCounts1 = async (data) => {
        try {
            setLoading(true);
            const newData = await Promise.all(data.map(async (item) => {
                const response = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${item.userId}&level=1`);

                response.data.map(async (item) => {
                    axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${item.refUserId}`)
                        .then(async (res) => {

                            const response1 = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${res.data[0].userId}&level=1`);


                            var members = []

                            var direct = []

                            const m = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${res.data[0].userId}`);

                            Promise.all(m.data.map(async (item1) => {
                                    
                                    const res = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${item1.refUserId}`);
                                    if (res.data[0]?.auth === "true" || res.data[0]?.auth === "free") {
                                        members.push(res.data[0]);
                                    }
    
                                }))
                                .then(()=>{
                                    res.data[0].members = members.length;
                                
                                })


                            response1.data.map(async (item) => {

                                console.log("item", item)

                                axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${item.refUserId}`)
                                    .then((res) => {
                                        console.log("res", res.data)

                                        res.data.map(async (item1) => {
                                            if (item1.auth === "true") {
                                                console.log("auth data", item1)
                                                direct.push(item1);
                                            }

                                        })
                                    }
                                    )
                                    .then(() => {
                                        res.data[0].direct = direct.length;
                                        console.log(direct)

                                    })

                            })
                            if (direct.length == 0) {

                                res.data[0].direct = 0;

                            }

                            setDirectMembers((prev) => {
                                return [...prev, res.data[0]];
                            }
                            )
                            setLoading(false);
                        })
                })
                const direct = [];
                const members = [];
                let refUserName = '', refUserId = '';

                const m = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${item.userId}`);

                await Promise.all(m.map(async (item1) => {

                    const res = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${item1.refUserId}`);
                    if (res[0]?.auth === "true" || res[0]?.auth === "free") {
                        members.push(res[0]);
                    }

                }));

                await Promise.all(response.data.map(async (item1) => {
                    try {
                        const res = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${item1.refUserId}`);

                        if (res[0]?.auth === "true" ) {
                            direct.push(res[0]);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }));

                const res = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlm/?refUserId=${item.userId}&level=1`);
                const userData = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${res[0]?.userID}`);

                refUserName = userData[0]?.name;
                refUserId = userData[0]?.userId;
                const direct1 = direct.length;
                const members1 = members.length;
                return { ...item, direct: direct1, refUserName, refUserId, members: members1 };
            }));
            setData(newData);
        } catch (error) {
            console.error("Error fetching direct counts:", error);
        }
    };
    useEffect(() => {

        if (data.length > 0) {
            fetchDirectCounts();
        }
    }, [check]);

    const handleUserIdSearch = () => {
        if (userId === "") {
            alert("Please Enter User Id");
            return;
        }
        axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${userId.toUpperCase()}`)
            .then((res) => {
                if (res.data.length === 0) {
                    alert("No Data Found");
                }

                setData([]);
                setDirectMembers([]);
                fetchDirectCounts1(res.data);
            })
            .catch((err) => {
                console.error("Error searching user by User Id:", err);
                alert("Failed to search user by User Id");
            });
    };



    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">View Tree</h4>
                <p className="card-description">
                    Search User by <code>USER ID.</code>
                </p>
                <div className="table-responsive">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", color: "black" }}>
                        <div className="form-group">
                            <label htmlFor="exampleInputUsername1">USER ID</label>
                            <input

                                type="text"
                                className="form-control"
                                id="exampleInputUsername1"
                                placeholder="USER ID"
                                style={{ width: "300px" }}
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={handleUserIdSearch}
                            type="button"
                            className="btn btn-primary mr-2"
                            style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "150px", height: "50px" }}
                        >
                            Search
                        </button>
                    </div>

                    <table className="table" style={{ color: "black" }}>
                        <thead>
                            <tr>
                                <th>S.NO</th>

                                <th> REF USER ID </th>
                                <th> REF USER NAME </th>

                                <th> USER ID </th>
                                <th> USER NAME </th>
                                <th> EMAIL </th>
                                <th> MOBILE </th>
                                <th> PASSWORD </th>
                                <th> LOCATION</th>
                                <th> TOTAL MEMBER </th>
                                <th> DIRECT </th>
                                <th> IS ACTIVE </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.refUserId}</td>
                                    <td>{item.refUserName}</td>
                                    <td>{item.userId}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.mobileNumber}</td>
                                    <td>{item.password}</td>
                                    <td>{item.location}</td>
                                    <td>{item.members}</td>
                                    <td>{item.direct}</td>
                                    <td>{item.auth === "true" ? item.auth.toUpperCase() + " - " + item.Vdate :item.auth === "false"? "FALSE":"FREE"}</td>


                                </tr>
                            ))}

                            {
                                data.length === 0 && (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center" }}>No Data Found</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>


                    <br />
                    
               
                    {
                        data.length > 0 &&
                        <>
                            <h3 className="card-title">DIRECT MEMBERS</h3>
                            <table className="table" style={{ color: "black" }}>
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th> USER ID </th>
                                        <th> USER NAME </th>
                                        <th> EMAIL </th>
                                        <th> MOBILE </th>
                                        <th> PASSWORD </th>
                                        <th> LOCATION</th>
                                        <th> MEMBER </th>
                                        <th> DIRECT </th>
                                        <th> IS ACTIVE </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {directMembers.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.userId}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobileNumber}</td>
                                            <td>{item.password}</td>
                                            <td>{item.location}</td>
                                            <td>{item.members}</td>
                                            <td>{item.direct}</td>
                                            <td>{item.auth === "true" ? item.auth.toUpperCase() : item.auth === "false" ? "FALSE" : "FREE"}</td>

                                        </tr>
                                    ))}

                                    {
                                        directMembers.length === 0 && (
                                            <tr>
                                                <td colSpan="10" style={{ textAlign: "center" }}>No Data Found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>

                        </>
                    }



                </div>
            </div>
        </div>
    );
}
