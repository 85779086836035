import axios from 'axios'
import React, { useEffect } from 'react'

export default function Package() {

    const [data,setData] = React.useState([])
    const [data1,setData1] = React.useState([])

    useEffect(()=>{
                axios.get('https://api.dremerz.net/api/SmartSolutionMlmCutoffData/1/')
                .then((res)=>{
                    console.log(res.data)
                    setData(res.data)
                    setData1(res.data)
                })
                .catch((err)=>{
                    console.log(err)
                })
    },[])
  return (
    <div className="card" style={{color:"black"}}>
    <div className="card-body">
      <h4 className="card-title">Package Details</h4>
      <p className="card-description"> Edit Package Details </p>
      <form className="forms-sample">
        <div className="form-group">
          <label htmlFor="exampleInputUsername1">Package</label>
          <input
            type="text"
            className="form-control"
            id="exampleInputUsername1"
            value={data.package}
                onChange={(e)=>{
                    setData({...data,package:e.target.value})
                }}

          />
        </div>


<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",flexWrap:"wrap"}}>

<div className="form-group" >
          <label htmlFor="exampleInputEmail1">Direct / Level 1 </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            value={data.level1}
            disabled
                onChange={(e)=>{
                    setData({...data,level1:e.target.value})
                }}
          />
        </div>
    
        <div className="form-group"  >
          <label htmlFor="exampleInputEmail1">Level 2 </label>
          <input
             type="text"
            className="form-control"
            id="exampleInputEmail1"
            value={data.level2}
            disabled
                onChange={(e)=>{
                    setData({...data,level2:e.target.value})
                }}
          />
        </div>

        <div className="form-group"  >
          <label htmlFor="exampleInputEmail1">Level 3 - Level 5</label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            value={data.level3}
            disabled
                onChange={(e)=>{
                    setData({...data,level3:e.target.value})
                }}
          />
        </div>


        <div className="form-group"  >
          <label htmlFor="exampleInputEmail1">Level 6 - Level 9 </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            value={data.level4}
            disabled
                onChange={(e)=>{
                    setData({...data,level4:e.target.value})
                }}
          />
        </div>


        <div className="form-group"  >
          <label htmlFor="exampleInputEmail1">Level 10 - Level 15 </label>
          <input
             type="text"
            className="form-control"
            id="exampleInputEmail1"
            value={data.level5}
            disabled
                onChange={(e)=>{
                    setData({...data,level5:e.target.value})
                }}
          />
        </div>



        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Level 16 + </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            disabled
            value={data.level6}
                onChange={(e)=>{
                    setData({...data,level6:e.target.value})
                }}
          />
        </div>
</div>




        <div className="form-check form-check-flat form-check-primary">
      
        </div>
        <div
        
        onClick={()=>{
            if(data){
            
              // check whick fields are changed 
              let obj = {}
              for (const key in data) {
                if (data[key] !== data1[key]) {
                  obj[key] = data[key]
                }
              }
              console.log(obj)
            
                axios.put('https://api.dremerz.net/api/SmartSolutionMlmCutoffData/1/',data)
                .then((res)=>{
                    console.log(res.data)
                    alert('Data Updated')
                })
                .catch((err)=>{
                    console.log(err)
                })
            }
            else{
                alert('Please fill all the fields')
            }
        }}
         className="btn btn-primary mr-2">
          Submit
        </div>
        <button className="btn btn-light">Cancel</button>
      </form>
    </div>
  </div>
  
  )
}
