import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function WithdrawRequest() {
    const [users, setUsers] = useState([]);
    const [cutOff, setCutOff] = useState({});

    useEffect(() => {
        axios.get('https://api.dremerz.net/api/SmartSolutionMlmCutoffData/1/')
            .then((response) => {
                setCutOff(response.data);
            })
            .catch((error) => {
                console.log("Error fetching cutoff data:", error);
            });
    }, []);

    useEffect(() => {
        axios.get('https://api.dremerz.net/api/SmartSolutionMlmWith/')
            .then((res) => {
              
                setUsers(res.data.filter((user) => user.amount === "0"));

               
            })
            .catch((err) => {
                console.error("Error fetching MLM data:", err);
            });
    }, []);



    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Withdraw Request</h4>
                <div className="table-responsive">
                    <table className="table" style={{ color: "black" }}>
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>USER ID</th>
                                <th>BANK NAME</th>
                                <th>ACCOUNT HOLDER NAME</th>
                                <th>ACCOUNT NUMBER</th>
                                <th>IFSC CODE</th>
                                <th>BRANCH NAME</th>
                                {/* <th>wallet</th> */}
                                <th>AMOUNT</th>
                                <th>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length !== 0 ? users.map((user, index) => (
                                <tr key={user.id}>
                                    <td>{index + 1}</td>
                                    <td>{user.userId}</td>
                                    <td>{user.BankName}</td>
                                    <td>{user.AccountHolderName}</td>
                                    <td>{user.AccountNumber}</td>
                                    <td>{user.IfscCode}</td>
                                    <td>{user.Branch}</td>
                                    {/* <td>{fetchTotal(user)}</td> */}
                                    <td>{user.amount1}</td>
                                    <td style={{display:"flex",justifyContent:"center"}}>
                                        <button
                                            onClick={() => {
                                                axios.put(`https://api.dremerz.net/api/SmartSolutionMlmWith/${user.id}/`, {
                                                   
                                                    amount: user.amount1
                                                })
                                                .then((res) => {
                                                    console.log(res.data);
                                                    alert('Withdraw Request Approved');
                                                    setUsers(users.filter((item) => item.id !== user.id));
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    alert('Error in Approving Withdraw Request');
                                                });
                                            }}
                                            type="submit" className="btn btn-primary mr-2" 
                                            style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "100px", height: "40px" }}
                                        >
                                            Approve
                                        </button>
                                        {/* // create. a adelete button */}
                                        <button
                                            onClick={() => {
                                                axios.delete(`https://api.dremerz.net/api/SmartSolutionMlmWith/${user.id}/`)
                                                .then((res) => {
                                                    console.log(res.data);
                                                    alert('Withdraw Request Rejected');
                                                    setUsers(users.filter((item) => item.id !== user.id));
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    alert('Error in Rejecting Withdraw Request');
                                                });
                                            }}
                                            type="submit" className="btn btn-danger" 
                                            style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "100px", height: "40px" }}
                                        >
                                            Reject
                                            </button>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan="10" style={{ textAlign: "center" }}>No Data Found</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
