import axios from 'axios';
import React, { useState } from 'react';

export default function ActivateAccount() {
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    if (!userId) {
      alert("Please Enter User Id");
      setLoading(false);
      return;
    }
    if (userId.length < 7) {
      alert("User Id must be at least 8 digits");
      setLoading(false);
      return;
    }

    try {
      const userRes = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${userId.toUpperCase()}`);
      const userData = userRes.data;

      if (userData.length === 0) {
        alert("No Data Found");
        setLoading(false);
        return;
      }

      const refRes = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?refUserId=${userId.toUpperCase()}&level=1`);
      if (refRes.data.length === 0) {
        alert("No Data Found");
        setLoading(false);
        return;
      }

      const refUserRes = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${refRes.data[0].userID}`);
      const updatedData = [...userData, { refId: refUserRes.data[0].userId, refName: refUserRes.data[0].name }];
      setUsers(updatedData);
      setLoading(false);

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const activateUser = async (authStatus) => {
    setLoading(true);
    if (users[0].auth === "true") {
      alert("User is already Active");
      setLoading(false);
      return;
    }

    try {
      await axios.put(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/${users[0].id}/`, {
        auth: authStatus,
        Vdate: (new Date().toLocaleDateString()).slice(0, 9) +"5",
      });

      const res = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?refUserId=${users[0].userId}`)
      .catch((error) => {
        console.error(error);
      });

      if (res.data.length === 0) {
        alert("No Data Found");
        setLoading(false);
        return;
      }
      console.log(res.data);

      for (const data of res.data) {
        await axios.put(`https://api.dremerz.net/api/SmartSolutionMlm/${data.id}/`, {
          refauth: authStatus,
          refVdate: (new Date().toLocaleDateString()).slice(0, 9) +"5" ,
        })
        .catch((error) => {
          console.error(error);
        });

      }

      alert('User Activated');
      setLoading(false);

    } catch (error) {
      console.error(error);
      setLoading(false);

    }
  };


  const deActivateUser = async (authStatus) => {
    setLoading(true);
    if (users[0].auth === "false") {
      alert("User is already Deactivated");
      setLoading(false);
      return;
    }

    try {
      await axios.put(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/${users[0].id}/`, {
        auth: authStatus,
        Vdate: (new Date().toLocaleDateString()).slice(0, 9) +"5" ,
      });

      const res = await axios.get(`https://api.dremerz.net/api/SmartSolutionMlm/?refUserId=${users[0].userId}`)
      .catch((error) => {
        console.error(error);
      });

      if (res.data.length === 0) {
        alert("No Data Found");
        setLoading(false);
        return;
      }
      console.log(res.data);

      for (const data of res.data) {
        await axios.put(`https://api.dremerz.net/api/SmartSolutionMlm/${data.id}/`, {
          refauth: authStatus,
          refVdate: (new Date().toLocaleDateString()).slice(0, 9) +"5" ,
        })
        .catch((error) => {
          console.error(error);
        });

      }

      alert('User Deactivated');
      setLoading(false);

    } catch (error) {
      console.error(error);
      setLoading(false);

    }
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Activate Account</h4>
        <p className="card-description">
          Search User by <code>USER ID</code>.
        </p>
        <div className="table-responsive">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", color: "black" }}>
            <div className="form-group">
              <label htmlFor="exampleInputUsername1">USER ID</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputUsername1"
                placeholder="USER ID"
                style={{ width: "300px" }}
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>
            <button
              onClick={handleSearch}
              className="btn btn-primary mr-2"
              style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "150px", height: "50px" }}
            >
              Search
            </button>
          </div>
          <table className="table" style={{ color: "black" }}>
            <thead>
              <tr>
                <th>Ref USER ID</th>
                <th>Ref Name</th>
                <th>USER ID</th>
                <th>NAME</th>
                <th>MOBILE</th>
                <th>EMAIL</th>
                <th>PASSWORD</th>
                <th>IS ACTIVE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {users.length ? (
                <tr>
                  <td>{users[1]?.refId}</td>
                  <td>{users[1]?.refName}</td>
                  <td>{users[0]?.userId}</td>
                  <td>{users[0]?.name}</td>
                  <td>{users[0]?.mobileNumber}</td>
                  <td>{users[0]?.email}</td>
                  <td>{users[0]?.password}</td>
                  <td>{users[0]?.auth === "true" ? `${users[0]?.auth.toUpperCase()} - ${users[0]?.Vdate}` : "FALSE"}</td>
                  <td style={{ display: "flex", justifyContent: "space-between" }}>

                    {
                      loading ? "Loading..." :
                      <>
                       <button

onClick={() => activateUser('true')}
className="btn btn-primary mr-2"
>
Activate
</button>
<button
onClick={() => activateUser('free')}
className="btn btn-primary mr-2"
>
Free Activate
</button>

<button
onClick={() => deActivateUser('false')}
className="btn btn-primary mr-2"
>
Deactivate
</button>


</>
                    }
                   
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    {loading ? "Loading..." : "No Data Found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
