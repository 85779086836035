import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Nav from './Nav';
import SideBar from './SideBar';
import Package from './Package';
import Spinner from './Spinner';
import ActivateAccount from './ActivateAccount';
import WithdrawRequest from './WithdrawRequest';
import Deposite from './Deposite';
import Viewusers from './Viewusers';
import Tree from './Tree';
import axios from 'axios';

export default function App() {

  return (
    <BrowserRouter>
      <Nav></Nav>
      <div class="container-fluid page-body-wrapper">
        <SideBar/>
        <div class="main-panel">
          <div class="content-wrapper">
            <Routes>
              <Route index element={<Package />} />
              <Route path='/package' element={<Package />} />
              <Route path='/spinner' element={<Spinner />} />
              <Route path='/activate-account' element={<ActivateAccount />} />
              <Route path='/withdraw-request' element={<WithdrawRequest />} />
              <Route path='/deposite-request' element={<Deposite />} />
              <Route path='/view-users' element={<Viewusers />} />
              <Route path='/view-tree' element={<Tree />} />



              {/* <Route path="*" element={<Spinner />} /> */}
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>


  )
}
