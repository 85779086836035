import React from 'react'
import { Link } from 'react-router-dom'

export default function SideBar() {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
  <ul className="nav">
 
    <li className="nav-item">
      <Link to={"/activate-account"}  className="nav-link" >
        <i className="mdi mdi-home menu-icon" />
        <span className="menu-title">Activate Account</span>
    </Link>
    </li>

     



     
    <li className="nav-item">
      <Link to={"/deposite-request"}  className="nav-link" >
        <i className="mdi mdi-cash-multiple menu-icon" />
        <span className="menu-title">Direct Deposit</span>
    </Link>
    </li>


    <li className="nav-item">
      <Link to={"/withdraw-request"}  className="nav-link" >
        {/* <i className="mdi mdi-magnify menu-icon" /> */}
        <i className="mdi mdi-cash-multiple menu-icon" />

        <span className="menu-title">Withdrawal Request</span>
    </Link>
    </li>
     
    <li className="nav-item">
      <Link to={"/package"} className="nav-link" >
        <i className="mdi mdi-package-variant menu-icon" />
        <span className="menu-title">Package Details</span>
    </Link>
    </li>


     
    <li className="nav-item">
      <Link  to={"/view-users"} className="nav-link" >
        <i className="mdi mdi-account-multiple menu-icon" />
        <span className="menu-title">View Users</span>
    </Link>
    </li>


    <li className="nav-item">
      <Link to={"/view-tree"}  className="nav-link" >
        <i className="mdi mdi-receipt menu-icon" />
        <span className="menu-title">View Tree Structure</span>
    </Link>
    </li>




  </ul>
</nav>

  )
}
