    import React, { useEffect, useState } from 'react';
    import axios from 'axios';
    import * as XLSX from 'xlsx';
    import FileSaver from 'file-saver';

    export default function ViewUsers() {
        const [data, setData] = useState([]);
        const [data1, setData1] = useState([]);
        const [userId, setUserId] = useState("");
        const [userId1, setUserId1] = useState("");
        const [auth, setAuth] = useState("");
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await axios.get('https://api.dremerz.net/api/SmartSolutionMlmLoginData/');
                    fetchDirectCounts1(response.data);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchData();
        }, []);

        const fetchData = async (url) => {
            try {
                const response = await axios.get(url);
                return response.data;
            } catch (error) {
                console.error("Error fetching data:", error);
                throw error;
            }
        };



        const fetchDirectCounts1 = async (data) => {
            setLoading(true);
            try {
                const newData = await Promise.all(data.map(async (item) => {
                    try {
                        const response = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${item.userId}&level=1`);
                        const response1 = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlm/?userID=${item.userId}`);
                        const direct = [];
                        let refUserName = '', refUserId = '';

                        await Promise.all(response.map(async (item1) => {
                            try {
                                const res = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${item1.refUserId}`);
                                if (res[0]?.auth === "true") {
                                    direct.push(res[0]);
                                }
                            } catch (err) {
                                console.log(err);
                            }
                        }));

                        const res = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlm/?refUserId=${item.userId}&level=1`);
                        const userData = await fetchData(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${res[0]?.userID}`);

                        refUserName = userData[0]?.name;
                        refUserId = userData[0]?.userId;

                        const direct1 = direct.length;

                        return { ...item, direct: direct1, refUserName, refUserId, members: response1.length };
                    } catch (err) {
                        console.error("Error in processing data:", err);
                    }
                }));

                setData(newData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching direct counts:", error);
            }
        };



        const handleUserIdSearch = () => {
            if (userId === "") {
                alert("Please Enter User Id");
                return;
            }
            axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${userId.toUpperCase()}`)
                .then((res) => {
                    if (res.data.length === 0) {
                        alert("No Data Found");
                    }
                    setData(res.data);
                    fetchDirectCounts1(res.data);
                })
                .catch((err) => {
                    console.error("Error searching user by User Id:", err);
                    alert("Failed to search user by User Id");
                });
        };


        const handleUserIdSearch1 = () => {
            if (userId1 === "") {
                alert("Please Enter User Id");
                return;
            }
            axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?mobileNumber=${userId1}`)
                .then((res) => {
                    if (res.data.length === 0) {
                        alert("No Data Found");
                    }
                    setData(res.data);
                    fetchDirectCounts1(res.data);
                })
                .catch((err) => {
                    console.error("Error searching user by User Id:", err);
                    alert("Failed to search user by User Id");
                });
        };

        const handleAuthSearch = () => {
            if (auth === "") {
                alert("Please Select User Status");
                return;
            }
            axios.get(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?auth=${auth}`)
                .then((res) => {
                    if (res.data.length === 0) {
                        alert("No Data Found");
                    }
                    setData(res.data);
                    fetchDirectCounts1(res.data);
                })
                .catch((err) => {
                    console.error("Error searching user by User Status:", err);
                    alert("Failed to search user by User Status");
                });
        };

        const exportToExcel = () => {
            const fileName = 'UserList';
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data1, fileName + fileExtension);
        };

        return (
            <div className="card">
                <div className="card-body">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h4 className="card-title">View Users</h4>
                            <p className="card-description">
                                Search User by <code>USER ID.</code>
                            </p>
                        </div>
                        <div>
                            <button
                                className='btn btn-primary'
                                style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "150px", height: "50px" }}
                                onClick={exportToExcel}
                            >
                                Export to Excel
                            </button>
                        </div>
                    </div>
                    <div></div>
                    <div className="table-responsive">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", color: "black" }}>
                            <div className="form-group">
                                <label htmlFor="exampleInputUsername1">USER ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputUsername1"
                                    placeholder="USER ID"
                                    style={{ width: "300px" }}
                                    value={userId}
                                    onChange={(e) => setUserId(e.target.value)}
                                />
                            </div>
                            <button
                                onClick={handleUserIdSearch}
                                type="button"
                                className="btn btn-primary mr-2"
                                style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "150px", height: "50px" }}
                            >
                                Search
                            </button>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", color: "black" }}>
                            <div className="form-group">
                                <label htmlFor="exampleInputUsername1">Mobile Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputUsername1"
                                    placeholder="Mobile Number"
                                    style={{ width: "300px" }}
                                    value={userId1}
                                    onChange={(e) => setUserId1(e.target.value)}
                                />
                            </div>
                            <button
                                onClick={handleUserIdSearch1}
                                type="button"
                                className="btn btn-primary mr-2"
                                style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "150px", height: "50px" }}
                            >
                                Search
                            </button>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", color: "black" }}>
                            <div className="form-group">
                                <label htmlFor="exampleInputUsername2">IS ACTIVE</label>
                                <select
                                    className="form-control"
                                    id="exampleInputUsername2"
                                    style={{ width: "300px" }}
                                    value={auth}
                                    onChange={(e) => setAuth(e.target.value)}
                                >
                                    <option value="">SELECT</option>
                                    <option value="true">TRUE</option>
                                    <option value="false">FALSE</option>
                                    <option value="free">FREE</option>
                                </select>
                            </div>
                            <button
                                onClick={handleAuthSearch}
                                type="button"
                                className="btn btn-primary mr-2"
                                style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "150px", height: "50px" }}
                            >
                                Search
                            </button>
                        </div>
                        <table className="table" style={{ color: "black" }}>
                            <thead>
                                <tr>
                                    <th>S.NO</th>
                                    <th>REFF USER ID</th>
                                    <th>REFF USER NAME</th>
                                    <th>USER ID</th>
                                    <th>USER NAME</th>
                                    <th>EMAIL</th>
                                    <th>MOBILE</th>
                                    <th>PASSWORD</th>
                                    <th>LOCATION</th>
                                    <th>MEMBERS</th>
                                    <th>DIRECT</th>
                                    <th>IS ACTIVE</th>
                                    <th>VALIDITY</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    loading&& 
                                    <tr>
                                        <td colSpan="14" style={{ display: "flex", justifyContent: "center" }}>
                                            <img src={require('./spinner.gif')} alt="spinner" />
                                        </td>
                                    </tr>
                                }
                            
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.refUserId}</td>
                                        <td>{item.refUserName}</td>
                                        <td>{item.userId}</td>
                                        <td>
                                            <input type="text" value={item.name} onChange={(e) => {
                                                const newData = [...data];
                                                newData[index].name = e.target.value;
                                                setData(newData);
                                            }
                                            } />
                                        </td>
                                           
                                        <td>
                                            <input type="text" value={item.email} onChange={(e) => {
                                                const newData = [...data];
                                                newData[index].email = e.target.value;
                                                setData(newData);
                                            }
                                            } />
                                        </td>
                                        <td>
                                            <input type="text" value={item.mobileNumber} onChange={(e) => {
                                                const newData = [...data];
                                                newData[index].mobileNumber = e.target.value;
                                                setData(newData);
                                            }
                                            } />
                                        </td>
                                        <td>
                                            <input type="text" value={item.password} onChange={(e) => {
                                                const newData = [...data];
                                                newData[index].password = e.target.value;
                                                setData(newData);
                                            }
                                            } />
                                        </td>
                                        <td>
                                            <input type="text" value={item.location} onChange={(e) => {
                                                const newData = [...data];
                                                newData[index].location = e.target.value;
                                                setData(newData);
                                            }
                                            } />
                                        </td>
                                        <td>{item.members}</td>
                                        <td>{item.direct}</td>
                                        <td>{item.auth === "true" ? item.auth.toUpperCase() + " - " + item.Vdate : item.auth === "false" ? "FALSE" : "FREE"}</td>
                                        <td>{item.Vdate}</td>
                                        <td style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div
                                                onClick={() => {
                                                  axios.put(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/${item.id}/`, {
                                                        name: item.name,
                                                        email: item.email,
                                                        mobileNumber: item.mobileNumber,
                                                        password: item.password,
                                                        location: item.location,
                                                    })
                                                        .then((res) => {
                                                            console.log(res.data);
                                                            alert('User Details Updated');
                                                        })
                                                        .catch((err) => {
                                                            console.error("Error updating user details:", err);
                                                            alert("Failed to update user details");
                                                        });
                                                }
                                                }
                                                type="button"
                                                className="btn btn-primary mr-2"
                                                style={{ alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", width: "100px", height: "40px" }}
                                            >
                                                Update
                                                </div>


                                  
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
