import axios from 'axios';
import React, { useEffect } from 'react';


export default function Deposite() {

    const [users, setUsers] = React.useState([]);

    const [userId , setUserId] = React.useState("");

    const [amount , setAmount] = React.useState("");
    const [wallet , setWallet] = React.useState("loading...");

    useEffect(()=>{
            axios.get(`https://api.dremerz.net/api/SmartSolutionMlmWith/?userId=${userId.toUpperCase()}`)
            .then((res)=>{
              console.log(res.data)
                if(res.data.length === 0){
                   setWallet("0")
                }
                else{
                    res.data.reduce((acc,curr)=>{
                        setWallet(parseInt(acc)+ parseInt(curr.amount))
                            
                    }
                    ,0)
                }
            })
    },[])


  return (
<div className="card">
  <div className="card-body">
    <h4 className="card-title">Deposit</h4>
    <p className="card-description">
      Search User by <code>USER ID.</code>
    </p>
    <div className="table-responsive" >
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center",color:"black"}}>
        <div className="form-group">
          <label htmlFor="exampleInputUsername1">USER ID</label>
          <input
            type="text"
            className="form-control"
            id="exampleInputUsername1"
            placeholder="USER ID"
           style={{width:"300px"}}
              value={userId}
                onChange={(e)=>{
                    setUserId(e.target.value)
                }}
          />
         
            </div>

            <div
            
                onClick={(()=>{
                    if (userId === "") {
                        alert("Please Enter User Id")
                    }
                    else if (userId.length < 7) {
                        alert("User Id must be 8 digit")
                    }
                    else{
                        fetch(`https://api.dremerz.net/api/SmartSolutionMlmLoginData/?userId=${userId.toUpperCase()}`)
                        .then((res)=>res.json())
                        .then((data)=>{
                            if(data.length === 0){
                                alert("No Data Found")
                            }
                            console.log(data)
                            setUsers(data)
                        })
                        .catch((err)=>{
                            console.log(err)
                        })
                    }
                })}
            type="submit" className="btn btn-primary mr-2" style={{alignItems:"center",textAlign:"center",justifyContent:"center",display:"flex",width:"150px",height:"50px"}}>
                Search
            </div>
        
        </div>
      <table className="table" style={{color:"black"}}>
        <thead>
          <tr>
           
            <th>USER ID</th>
            <th>NAME</th>
            <th>MOBILE</th>
           
            <th>IS ACTIVE</th>
            {/* <th>WALLET</th> */}
            <th>AMOUNT</th>
           <th>ACTION</th>

          </tr>
        </thead>
        <tbody>


            <tr>

                {
                    users.length !=0 ? 
                    <>
                    
                    <td>{users[0].userId}</td>
                    <td>{users[0].name}</td>
                    <td>{users[0].mobileNumber}</td>
                    
                    <td>{(users[0].auth).toUpperCase()}</td>
                    {/* <td>{wallet}</td> */}

                    <td className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        value={amount}
                            onChange={(e)=>{
                                setAmount(e.target.value)
                            }}

                            style={{width:"150px"}}
                    />
                    </td>

                    <td>
                        <div
                            onClick={()=>{
                              if (users[0].auth != "true") {
                                alert("User is not Active")
                              }
                              else{
                               axios.post(`https://api.dremerz.net/api/SmartSolutionMlmDep/`,{
                                      userId:userId.toUpperCase(),
                                      amount:amount,
                                 })
                                    .then((res)=>{
                                        console.log(res.data)
                                        alert('Amount Deposited')
                                    })
                                    .catch((err)=>{
                                        console.log(err)
                                    })
                              

                              }
                            }}
                        className="btn btn-primary mr-2">
                           DEPOSIT
                        </div>
                    </td>
                    </>
                    :
                    <td colSpan="8" style={{textAlign:"center"}}>No Data Found</td>
                }

                </tr>
      
        </tbody>
      </table>
    </div>
  </div>
</div>

  )
}
